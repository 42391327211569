import { render, staticRenderFns } from "./CriteriaPriority.vue?vue&type=template&id=3952f5bb&scoped=true&"
import script from "./CriteriaPriority.vue?vue&type=script&lang=js&"
export * from "./CriteriaPriority.vue?vue&type=script&lang=js&"
import style0 from "./CriteriaPriority.vue?vue&type=style&index=0&id=3952f5bb&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3952f5bb",
  null
  
)

export default component.exports